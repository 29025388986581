import * as React from 'react';

import Layout from '../components/layout';
// styles
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
const headingAccentStyles = {
  color: '#663399',
};
const paragraphStyles = {
  marginBottom: 48,
};

// markup
const Transcricao = () => (
  <Layout>
    <title>Torne fala em texto em tempo real</title>
    <h1 style={headingStyles}>
      Congratulations
      <br />
      <span style={headingAccentStyles}>Transcreva áudio em texto de forma rápida e precisa em mais de 85 idiomas e variantes.</span>
      <span role="img" aria-label="Party popper emojis">
        Obtenha mais valor do áudio falado, permitindo pesquisa ou análise em texto transcrito em tempo real
      </span>
    </h1>
    <p style={paragraphStyles}>
      Obtenha transcrições precisas com o reconhecimento de fala com tecnologia de ponta.
      <span role="img" aria-label="Sunglasses smiley emoji">
        😎
      </span>
    </p>
    <h2>Transcrever a fala com precisão de várias fontes</h2>
    <p style={paragraphStyles}>
      Converta áudio em texto de uma variedade de fontes, incluindo microfones, arquivos de áudio e armazenamento na nuvem, etc.
      Obtenha transcrições legíveis com formatação e pontuação automáticas.
    </p>
    <h2>Privacidade e segurança</h2>
    <p style={paragraphStyles}>
      O serviço de inteligência artificial aplicado é certificado por SOC, FedRAMP, PCI DSS, HIPAA, HITECH e ISO.
    </p>
    <p style={paragraphStyles}>
      Os seus dados permanecem sendo seus. Os seus dados de entrada e de transcrição de áudio não são registrados durante o processamento de áudio.
    </p>
    <p style={paragraphStyles}>
      Com o suporte da infraestrutura em nuvem, o serviço oferece segurança, disponibilidade e capacidade de gerenciamento de nível empresarial.
    </p>
  </Layout>
);

export default Transcricao;
